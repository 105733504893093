import { clear } from '@testing-library/user-event/dist/clear';
import React, { Component } from 'react'
import * as THREE from "three";
import { SVGRenderer } from 'three/examples/jsm/renderers/SVGRenderer.js';

class OrbitalRings extends Component {
    componentDidMount() {
        const {
            attachToId = null,
            targetFps = 60,
            innerRingCount = 3,
            innerRingColors = ['white', 'white', 'white'],
            outerRingColor = 'white'
        } = this.props;

        let clock = new THREE.Clock();
        let delta = 0;
        let interval = 1 / targetFps;

        var scene = new THREE.Scene();
        var camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
        camera.position.z = 5;
        var renderer = new THREE.WebGLRenderer({ antialias: false, alpha: true });
        //var renderer = new SVGRenderer();
        renderer.setSize(window.innerWidth, window.innerHeight);
        renderer.setClearColor(0x000000, 0);
        if (attachToId == null) document.body.appendChild(renderer.domElement);
        else document.getElementById(attachToId).appendChild(renderer.domElement);

        const vertices = [];
        const divisions = 50;
        for (let i = 0; i <= divisions; i++) {
            const v = (i / divisions) * (Math.PI * 2);
            const x = Math.sin(v);
            const z = Math.cos(v);
            vertices.push(x, 0, z);
        }

        const geometry = new THREE.BufferGeometry();
        geometry.setAttribute('position', new THREE.Float32BufferAttribute(vertices, 3));

        // Inner rings.
        for (let i = 1; i <= innerRingCount; i++) {

            var newColor = i <= innerRingColors.length ? innerRingColors[i - 1]
                : Math.random() * 0xffffff;
            const material = new THREE.LineBasicMaterial({
                color: newColor,
                linewidth: 1
            });
            const line = new THREE.Line(geometry, material);
            line.scale.setScalar(i / 3);
            scene.add(line);

        }
        // Outer ring.
        const material = new THREE.LineDashedMaterial({
            color: outerRingColor,
            linewidth: 1,
            dashSize: 10,
            gapSize: 10
        });
        const line = new THREE.Line(geometry, material);
        line.scale.setScalar(2);
        scene.add(line);

        function onWindowResize() {
            camera.aspect = window.innerWidth / window.innerHeight;
            camera.updateProjectionMatrix();
            renderer.setSize(window.innerWidth, window.innerHeight);
        }
        window.addEventListener('resize', onWindowResize);

        function update() {
            requestAnimationFrame(update);
            let count = 0;
            const time = performance.now() / 1000;
            scene.traverse(function (child) {
                child.rotation.x = count + (time / 3);
                child.rotation.z = count + (time / 4);
                count++;
            });

            delta += clock.getDelta();
            if (delta > interval) {
                renderer.render(scene, camera);
                delta %= interval;
            }
        }
        update();
    }

    render() {
        return (
            <div ref={ref => (this.mount = ref)} />
        )
    }
}

export default OrbitalRings
