import React from 'react'
import PortalLinkEntry from './PortalLinkEntry'

const Hero = ({portalLinksCfg}) => {

    const contentStyle = {
        height: '100vh',
        display: 'flex',
        justifyContent: 'right', // Horizontally align content to right.
    }

    const portalLinksStyle = {
        width: '100%',
        display: 'flex',
        flexDirection: 'column', // Column layout.
        justifyContent: 'center', // Vertically align content to middle.
        alignItems: 'flex-end', // Horizontally align content to right.
        paddingRight: '6vw'
    }

    const verticalBarStyle = {
        position: 'absolute',
        borderLeft: "2.5px solid white",
        top: "0vh",
        height: "60vh",
        paddingRight: '6vw',
    }

    const fadeVerticalBarStyle = {
        position: 'absolute',
        top: "35vh",
        height: "30vh",
        paddingRight: '6vw',
        borderLeft: "3px solid white",
        borderTop: '0',
        borderTottom: '0',
        borderImage: 'linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%,'
            + 'rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 0) 100%)',
        borderImageSlice: '1',
    }

    if(!portalLinksCfg) return null;

    return (
        <div style={contentStyle}>
            <div style={portalLinksStyle}>
                {portalLinksCfg.map((portalLink, index) => (
                    <PortalLinkEntry
                        key={index}
                        label={portalLink.label}
                        link={portalLink.link}
                        newTab={portalLink.newTab}
                        labelColor={portalLink.labelColor}
                        invLabelColor={portalLink.invLabelColor}
                        boxColor={portalLink.boxColor}
                        bgColor='#96B7C4' />
                ))}

            </div>
            <div style={verticalBarStyle}></div>
        </div>
    )
}

export default Hero
