import React, { useEffect, useState } from 'react'
import styles from './styles';
import { Navbar, Hero } from './components';
import OrbitalRings from './components/OrbitalRings';
import OrbitalRingsConfig from './config/orbital-rings-config.json'

const App = () => {

  const [portalLinksCfg, setPortalLinksCfg] = useState([{}]);
  const orbitalRingsCfg = JSON.parse(JSON.stringify(OrbitalRingsConfig));

  useEffect(() => {
    fetch('config/portal-links-config.json')
      .then((res) => res.json())
      .then((data) => { setPortalLinksCfg(data) })
  }, []);

  return (
    <>
      <div className={`content ${styles.flexStart}`}>
        <Hero portalLinksCfg={portalLinksCfg} />
      </div>
      <div className='background' id='background'>
        <OrbitalRings
          attachToId='background'
          targetFps={orbitalRingsCfg.targetFps}
          innerRingCount={orbitalRingsCfg.innerRingCount}
          innerRingColors={orbitalRingsCfg.innerRingColors}
          outerRingColor={orbitalRingsCfg.outerRingColor}
        />
      </div>
    </>
  )
}

export default App
