import React from 'react'
import '../stylized-buttons.css'

const PortalLinkEntry = ({
    label = 'Label',
    link,
    newTab = true,
    boxColor = 'white',
    labelColor = 'white',
    invLabelColor = '#96B7C4',
    bgColor = '#96B7C4'
}) => {

    if (!invLabelColor) invLabelColor = bgColor;

    const btnContainerStyle = {
        '--textColor': labelColor,
        '--invTextColor': invLabelColor,
        '--boxColor': boxColor,
        display: 'flex',
        marginBottom: '15px',
    }

    const btnStyle = {
        padding: '0.5rem 3rem',
        paddingRight: '0.5rem',
        fontSize: '1.1rem',
        letterSpacing: '0.5rem',
        cursor: 'pointer',
        userSelect: 'none',
    }

    return (
        <div style={btnContainerStyle}>
                <a
                    href={link ? link : null}
                    target={newTab ? '_blank' : ''}
                    className='btn from-bottom'
                    style={btnStyle}>
                    {label}
                </a>
        </div>
    )
}

export default PortalLinkEntry
